import { motion, useAnimation, useInView } from "framer-motion";
import { useEffect, useRef } from "react";

const slideVariant = (slideDirection) => {
  switch (slideDirection) {
    case "left":
      return {
        hidden: { opacity: 0, x: "-100%" },
        visible: {
          opacity: 1,
          x: 0,
          transition: {
            duration: 0.6,
            ease: "easeIn"
          }
        }
      };
    case "right":
      return {
        hidden: { opacity: 0, x: "100%" },
        visible: {
          opacity: 1,
          x: 0,
          transition: {
            duration: 0.6,
            ease: "easeIn"
          }
        }
      };
    case "top":
      return {
        hidden: { opacity: 0, y: "-100%" },
        visible: {
          opacity: 1,
          y: 0,
          transition: {
            duration: 0.6,
            ease: "easeIn"
          }
        }
      };
    case "bottom":
      return {
        hidden: { opacity: 0, y: "100%" },
        visible: {
          opacity: 1,
          y: 0,
          transition: {
            duration: 0.6,
            ease: "easeIn"
          }
        }
      };
    default:
      return {
        hidden: { opacity: 0, x: "-100%" },
        visible: {
          opacity: 1,
          x: 0,
          transition: {
            duration: 0.6,
            ease: "easeIn"
          }
        }
      };
  }
};

const SlideAnimation = ({
  children,
  slideDirection = "bottom",
  showOnce = true,
  animationOptions = {}
}) => {
  const ref = useRef(null);
  const controls = useAnimation();
  const isInView = useInView(ref, { once: showOnce });

  useEffect(() => {
    if (isInView) {
      controls.start("visible");
    }
  }, [isInView, controls]);

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={{...slideVariant(slideDirection), ...animationOptions}}
    >
      {children}
    </motion.div>
  );
};

export default SlideAnimation;

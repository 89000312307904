"use client";

import { animate, useInView, useIsomorphicLayoutEffect } from "framer-motion";
import { useRef } from "react";

const AnimatedCounter = ({ from = 0, to, suffix = "", animationOptions }) => {
  const ref = useRef(null);
  const inView = useInView(ref, { once: true });

  useIsomorphicLayoutEffect(() => {
    const element = ref.current;

    if (!element || !inView) {
      // Return a no-op function to satisfy ESLint consistent-return rule
      return () => {};
    }

    element.textContent = String(from) + suffix; // Start with initial value

    if (window.matchMedia("(prefers-reduced-motion)").matches) {
      element.textContent = String(to) + suffix;
      return () => {}; // No-op cleanup function
    }

    const controls = animate(from, to, {
      duration: 2,
      ease: "easeOut",
      ...animationOptions,
      onUpdate(value) {
        element.textContent = value.toFixed(0) + suffix; // Update only the numeric part
      }
    });

    // Return the cleanup function to stop the animation on unmount
    return () => {
      controls.stop();
    };
  }, [ref, inView, from, to, suffix, animationOptions]);

  return <span ref={ref} />;
};

export default AnimatedCounter;

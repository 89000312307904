import Footer from "common/components/AppLayout/Footer";
import Header from "common/components/AppLayout/Header";
import EnneaInfo from "routes/Ennea/EnneaInfo";
import FeaturesHighlight from "routes/Ennea/FeaturesHighlight";
import Metrics from "routes/Ennea/Metrics";
import styled from "styled-components";

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  width: 100%;
  overflow: hidden;
  position: relative;
`;

const MobilePage = () => (
  <MainContainer>
    <Header />
    <EnneaInfo />
    <FeaturesHighlight />
    <Metrics />
    {/* <ContactUs /> */}
    <Footer />
  </MainContainer>
);

export default MobilePage;

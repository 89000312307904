import EnneaLogo from "common/icons/Ennea_logo.svg";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const OutsideContainer = styled.div`
  border-top: 1px solid #d9dbe9;
  width: 100%;
  ${(props) => props.showBackground && "background: #E7F2FF;"}
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin: 0 auto;
`;

const FooterContainer = styled.div`
  display: grid;
  grid-template-columns: 372px max-content;
  justify-content: space-between;
  align-items: flex-start;
  padding: 5.59rem 8.87rem 4.6rem;
`;

const LogoContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.79944rem;
`;

const Logo = styled.img`
  width: 13.86663rem;
  height: 9rem;
`;

const AddressText = styled.p`
  color: var(--Neutral-600, #6f6c90);
  font-feature-settings: "liga" off, "clig" off;
  font-family: Outfit;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.24931rem; /* 179.945% */
`;

const ContentContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 82px;
`;

const ContentCard = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
`;

const ContentTitle = styled.h4`
  color: #006eb2;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Outfit;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.6495rem; /* 109.966% */
  margin-bottom: 2rem;
`;

const CommonText = styled.p`
  color: #4f555a;
  text-align: center;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Outfit;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.49956rem;
  cursor: pointer;
  :hover {
    color: #006eb2;
  }
`;

const CopyRightContainer = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #d9dbe9;
  padding: 1.88rem 1.45rem;
  margin: 0 7.5rem;
`;

const CopyRightText = styled.p`
  color: var(--Neutral-600, #6f6c90);
  font-feature-settings: "liga" off, "clig" off;
  font-family: Outfit;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.24931rem; /* 179.945% */
`;

const LinkText = styled.a`
  color: var(--Primary-Color-1, #4a3aff);
  font-feature-settings: "liga" off, "clig" off;
  font-family: Outfit;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.24931rem;
  text-decoration-line: underline;
  cursor: pointer;
`;

const isMobileView = window.innerWidth < 800;

const Footer = ({ showBackground = false }) => {
  const navigate = useNavigate();

  return isMobileView ? (
    <MobileFooter />
  ) : (
    <OutsideContainer showBackground={showBackground}>
      <MainContainer>
        <FooterContainer>
          <LogoContainer>
            <Logo src={EnneaLogo} alt="logo" />
            <AddressText>
              <span>Ennea Solutions Pvt Ltd</span>
              <span>Plot No. 1293/A Road No. 63</span>
              <span>Jubilee Hills, Hyderabad - 500033</span>
            </AddressText>
          </LogoContainer>
          <ContentContainer>
            <ContentCard>
              <ContentTitle>Product Suite</ContentTitle>
              <CommonText onClick={() => navigate("/products/valuemedi")}>
                ValueMedi
              </CommonText>
              {/* <CommonText onClick={() => navigate("/products/adInsight")}>
                AdInSight
              </CommonText> */}
              <CommonText onClick={() => navigate("/products/valuesale")}>
                ValueSale
              </CommonText>
            </ContentCard>
            <ContentCard>
              <ContentTitle>About us</ContentTitle>
              <CommonText onClick={() => navigate("/company/leadership")}>
                Leadership
              </CommonText>
              <CommonText onClick={() => navigate("/company/careers")}>
                Careers
              </CommonText>
              <CommonText onClick={() => navigate("/company/contact-us")}>
                Contact us
              </CommonText>
            </ContentCard>
          </ContentContainer>
        </FooterContainer>
        <CopyRightContainer>
          <CopyRightText>Copyright © 2024 | All Rights Reserved</CopyRightText>
          <CopyRightText>
            <LinkText onClick={() => navigate("/terms")}>
              Terms and Conditions
            </LinkText>{" "}
            |{" "}
            <LinkText onClick={() => navigate("/privacy")}>
              Privacy Policy
            </LinkText>
          </CopyRightText>
        </CopyRightContainer>
      </MainContainer>
    </OutsideContainer>
  );
};

const MobileFooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.88rem 0;
  border-top: 1px solid #d9dbe9;
  background: #e7f2ff;
  width: 100%;
`;
const LogoImg = styled.img`
  width: 14rem;
  height: 8rem;
`;
const MobileAddressText = styled.p`
  color: var(--Neutral-600, #6f6c90);
  font-feature-settings: "liga" off, "clig" off;
  font-family: Outfit;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.24931rem; /* 179.945% */
  text-align: center;
  width: 19.25rem;
`;
const MobileCopyRightContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1.88rem;
  border-top: 1px solid #d9dbe9;
  width: 80%;
`;
const MobileFooter = () => {
  const navigate = useNavigate();

  return (
    <MobileFooterContainer>
      <LogoImg src={EnneaLogo} alt="logo" />
      <MobileAddressText>
        <span>Ennea Solutions Pvt Ltd, </span>
        <span>Plot No. 1293/A Road No.63, </span>
        <span>Jubilee Hills, Hyderabad - 500033</span>
      </MobileAddressText>
      <MobileCopyRightContainer>
        <CopyRightText>Copyright © 2024 | All Rights Reserved</CopyRightText>
        <CopyRightText>
          <LinkText onClick={() => navigate("/terms")}>
            Terms and Conditions
          </LinkText>{" "}
          |{" "}
          <LinkText onClick={() => navigate("/privacy")}>
            Privacy Policy
          </LinkText>
        </CopyRightText>
      </MobileCopyRightContainer>
    </MobileFooterContainer>
  );
};

export default Footer;

import AnimatedCounter from "common/components/AnimatedCounter";
import TextReveal from "common/components/TextReveal";
import { motion, useAnimation, useInView } from "framer-motion";
import { useEffect, useRef } from "react";
import styled from "styled-components";

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3.75rem;
  margin: 0 auto;
  margin-bottom: 8.67rem;
  @media screen and (max-width: 800px) {
    margin-bottom: 5rem;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.25rem;
  align-self: stretch;
`;

const Title = styled(motion.div)`
  color: #2d2d2d;
  text-align: center;
  font-family: Outfit;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 3.25rem */
  @media screen and (max-width: 800px) {
    width: 19.125rem;
    font-size: 1.75rem;
  }
  `;

const SubTitle = styled(motion.div)`
  color: #2d2d2d;
  text-align: center;
  font-family: Poppins;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 1.625rem */
  @media screen and (max-width: 800px) {
    width: 19.125rem;
    font-size: 1rem;
  }
`;

const MetricsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  align-items: flex-start;
  justify-content: center;
  gap: 5rem;
  max-width: 80%;
  margin: 0 auto;
  @media screen and (max-width: 800px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    gap: 1rem;
    width: 100vw;
  }
`;
const MetricCard = styled.div`
  display: flex;
  padding: 3rem 1rem;
  flex-direction: column;
  align-items: center;
  gap: 0.625rem;
  border-radius: 1.25rem 1.25rem 0rem 0rem;
  border-bottom: 4px solid #d3e6ff;
  background: var(--White, #fff);
  box-shadow: 34.854px 29.626px 48.34px 0px rgba(51, 102, 255, 0.1);
  @media screen and (max-width: 800px) {
    padding: 1.25rem 0.75rem;
    flex: 1 0 0;
  }
`;

const MetricTitle = styled.div`
  color: #006eb2;
  text-align: center;
  font-family: Poppins;
  font-size: 2.375rem;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 3.0875rem */
  letter-spacing: -0.0475rem;
  width: 8rem;
  @media screen and (max-width: 800px) {
    font-size: 1.75rem;
  }
`;

const Metric = styled.div`
  color: #2d2d2d;
  text-align: center;
  font-family: Poppins;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 1.625rem */
  white-space: nowrap;
  @media screen and (max-width: 800px) {
    font-size: 1rem;
  }
`;

const METRIC_DATA = [
  {
    id: 1,
    title: "Orders Serviced",
    value: "162K+"
  },
  {
    id: 2,
    title: "Transacted Value",
    value: "517Cr+"
  },
  {
    id: 3,
    title: "Retail",
    value: "250K+"
  },
  {
    id: 4,
    title: "Stockist",
    value: "10K+"
  },
  {
    id: 5,
    title: "HCP",
    value: "120K+"
  },
  {
    id: 6,
    title: "DOOH Units",
    value: "10K+"
  }
];

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
      delayChildren: 0.3
    }
  }
};

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.6 } }
};

const extractNumberAndSuffix = (value) => {
  const match = value.match(/^(\d+)([a-zA-Z+]*)$/);
  if (!match) return { number: 0, suffix: "" }; // Fallback in case of unexpected format

  const [prefix, number, suffix] = match;
  return { number: parseInt(number, 10), suffix, prefix };
};

const Metrics = () => {
  const ref = useRef(null);
  const controls = useAnimation();
  const isInView = useInView(ref, { once: true });

  useEffect(() => {
    if (isInView) {
      controls.start("visible");
    }
  }, [isInView, controls]);

  return (
    <MainContainer>
      <HeaderContainer>
        <Title
          initial="hidden"
          animate={controls}
          variants={{
            hidden: { opacity: 0, y: 75 },
            visible: {
              opacity: 1,
              y: 0,
              transition: {
                duration: 0.4,
                ease: "easeInOut"
              }
            }
          }}
          transition={{ delay: 0.3 }}
        >
          <TextReveal text="Our Metrics Tell the Story" />
        </Title>
        <SubTitle
          initial="hidden"
          animate={controls}
          variants={{
            hidden: { opacity: 0, y: 75 },
            visible: {
              opacity: 1,
              y: 0,
              transition: {
                duration: 0.4,
                ease: "easeInOut"
              }
            }
          }}
          transition={{ delay: 0.3 }}
        >
          <TextReveal
            text="Our metrics gives you the inside scoop on our success and impact of our products on the industry."
            animationOptions={{ staggerChildren: 0.01 }}
          />
        </SubTitle>
      </HeaderContainer>
      <MetricsContainer
        as={motion.div}
        variants={containerVariants}
        initial="hidden"
        animate={controls}
        ref={ref}
      >
        {METRIC_DATA.map((metric) => {
          const { number, suffix, prefix } = extractNumberAndSuffix(
            metric.value
          );
          return (
            <MetricCard key={metric.id} as={motion.div} variants={itemVariants}>
              <MetricTitle>
                <AnimatedCounter
                  from={0}
                  to={number}
                  suffix={suffix}
                  prefix={prefix}
                />
              </MetricTitle>
              <Metric>{metric.title}</Metric>
            </MetricCard>
          );
        })}
      </MetricsContainer>
    </MainContainer>
  );
};

export default Metrics;

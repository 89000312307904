import { GradientEllipse } from "common/components/StyledElements";
import { motion, useAnimation, useInView } from "framer-motion";
import { useEffect, useRef } from "react";
import styled from "styled-components";

const OutSideContainer = styled.div`
  display: flex;
  padding-top: 4.37rem;
  padding-bottom: 10.25rem;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 0;
`;

const MainContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 3.75rem;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.25rem;
  align-self: stretch;
`;

const HeaderTitle = styled(motion.div)`
  color: #000;
  text-align: center;
  font-family: Outfit;
  font-size: 2.375rem;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 3.0875rem */
`;

const SubTitle = styled(motion.div)`
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.875rem */
`;

const ContentContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 4rem;
  z-index: 10;
`;

const CardContainer = styled.div`
  display: flex;
  width: 23.125rem;
  padding: 2.5rem 1.875rem;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 1.25rem;
  border: 1px solid #d8d8d8;
  background: #fff;
  box-shadow: 34.854px 29.626px 48.34px 0px rgba(51, 102, 255, 0.05);
`;

const CardContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.875rem;
  align-self: stretch;
`;

const CardTitle = styled.div`
  color: #2d2d2d;
  text-align: center;
  font-family: Outfit;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 2.275rem */
  letter-spacing: -0.0175rem;
  align-self: stretch;
  width: 14rem;
  align-self: center;
`;

const CardImageContainer = styled.div`
  display: flex;
  width: 7.5rem;
  padding: 1.25rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 1.25rem;
  background: rgba(80, 192, 255, 0.2);
`;

const CardImage = styled.img`
  width: 3.25rem;
  height: 4.1875rem;
  flex-shrink: 0;
`;

const Ellipse = styled(GradientEllipse)`
  position: absolute;
  top: -5rem;
  right: -5.12rem;
`;

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
      delayChildren: 0.3
    }
  }
};

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.6 } }
};

const Features = ({ data }) => {
  const { title, description, features } = data;
  const ref = useRef(null);
  const controls = useAnimation();
  const isInView = useInView(ref, { once: true });

  useEffect(() => {
    if (isInView) {
      controls.start("visible");
    }
  }, [isInView, controls]);

  return (
    <OutSideContainer>
      <Ellipse />
      <MainContainer>
        <HeaderContainer>
          <HeaderTitle
            initial="hidden"
            animate={controls}
            variants={{
              hidden: { opacity: 0, y: 75 },
              visible: {
                opacity: 1,
                y: 0,
                transition: {
                  duration: 0.4,
                  ease: "easeInOut"
                }
              }
            }}
            transition={{ delay: 0.3 }}
          >
            {title}
          </HeaderTitle>
          <SubTitle
            initial="hidden"
            animate={controls}
            variants={{
              hidden: { opacity: 0, y: 75 },
              visible: {
                opacity: 1,
                y: 0,
                transition: {
                  duration: 0.4,
                  ease: "easeInOut"
                }
              }
            }}
            transition={{ delay: 0.3 }}
          >
            {description}
          </SubTitle>
        </HeaderContainer>
        <ContentContainer
          as={motion.div}
          variants={containerVariants}
          initial="hidden"
          animate={controls}
          ref={ref}
        >
          {features.map((feature) => (
            <CardContainer
              key={feature.id}
              as={motion.div}
              variants={itemVariants}
            >
              <CardContentContainer>
                <CardImageContainer>
                  <CardImage src={feature.image} alt={feature.title} />
                </CardImageContainer>
                <CardTitle>{feature.title}</CardTitle>
              </CardContentContainer>
            </CardContainer>
          ))}
        </ContentContainer>
      </MainContainer>
    </OutSideContainer>
  );
};

export default Features;

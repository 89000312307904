import { Suspense } from "react";
import { Outlet } from "react-router-dom";
import MobilePage from "routes/Mobile";
import styled from "styled-components";
import Footer from "./Footer";
import Header from "./Header";
import Loading from "./Loading";

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  width: 100%;
  overflow: hidden;
  position: relative;
`;

const AppLayout = () => {
  const isPathCompany = window.location.pathname.includes("company");
  if (window.innerWidth < 800) {
    return <MobilePage />;
  }

  return (
    <MainContainer>
      <Header />
      <Suspense fallback={<Loading />}>
        <Outlet />
      </Suspense>
      <Footer showBackground={isPathCompany} />
    </MainContainer>
  );
};

export default AppLayout;

import arrowDown from "common/icons/chevron_down.svg";
import EnneaLogo from "common/icons/Ennea_logo_nav.svg";
import MenuIcon from "common/icons/menu_icon.svg";
import { motion } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import BounceAnimation from "../BounceAnimation";

const MainContainer = styled.div`
  padding: 0 7.5rem;
  background-color: #fff;
  z-index: 1;
`;

const HeaderContainer = styled.div`
  display: flex;
  height: min-content;
  place-content: center;
  border-bottom: 1px solid #e2edf4;
`;

const HeaderWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  width: 100%;
`;

const LogoWrapper = styled(Link)`
  place-content: center;
  align-items: center;
  display: flex;
  padding: 1rem 2.81rem;
`;

const Logo = styled.img`
  width: 13.51038rem;
  height: 4.25rem;
`;

const HeaderText = styled.p`
  color: #18191f;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Outfit;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
`;

const DropDownMenuContainer = styled.div`
  display: inline-flex;
  align-items: flex-start;
  gap: 4rem;
  padding: 0 2.81rem;
  position: relative;
  z-index: 199;
`;

const CustomLink = styled.div`
  display: flex;
  gap: 0.75rem;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
  position: relative;
  cursor: pointer;
  z-index: 100;
`;

const Img = styled.img`
  width: 1.0625rem;
  height: 0.625rem;
  flex-shrink: 0;
`;

const DropdownContainer = styled(motion.div)`
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 100;
  background: #fff;
  border: 1px solid #d8d8d8;
  border-radius: 0 0 1.25rem 1.25rem;
  transition: opacity 0.3s ease, visibility 0.3s ease;
`;

const CustomLinkWrapper = styled.div`
  display: inline-flex;
  position: relative;
  z-index: 100;
  &:hover ${DropdownContainer} {
    opacity: 1;
    visibility: visible;
  }
`;

// Stagger Animation for Menu Items
const containerVariants = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2
    }
  }
};

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  show: { opacity: 1, y: 0 }
};

// Products DropDown styles
const ProductContainer = styled(motion.div)`
  display: inline-flex;
  padding: 2.5rem;
  align-items: flex-start;
  gap: 5.375rem;
  border-radius: 0rem 0rem 1.25rem 1.25rem;
  background: #fff;
  z-index: 999;
`;

const MenuContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
  z-index: 110;
`;

const LabelText = styled(motion.a)`
  color: #4f555a;
  text-align: center;
  font-family: Outfit;
  font-size: 1.375rem;
  font-weight: 400;
  line-height: 1.49956rem;
  cursor: pointer;
  :hover {
    color: #006eb2;
  }
`;

const ProductDropDown = () => {
  const navigate = useNavigate();

  return (
    <ProductContainer
      variants={containerVariants}
      initial="hidden"
      animate="show"
    >
      <MenuContainer>
        <LabelText
          variants={itemVariants}
          onClick={() => navigate("/products/valuemedi")}
        >
          ValueMedi
        </LabelText>
        {/* <LabelText
          variants={itemVariants}
          onClick={() => navigate("/products/adInsight")}
        >
          AdInSight
        </LabelText> */}
        <LabelText
          variants={itemVariants}
          onClick={() => navigate("/products/valuesale")}
        >
          ValueSale
        </LabelText>
      </MenuContainer>
    </ProductContainer>
  );
};

// About us Styles
const AboutUsContainer = styled(motion.div)`
  display: inline-flex;
  padding: 2.5rem;
  align-items: flex-start;
  gap: 5.375rem;
  border-radius: 0rem 0rem 1.25rem 1.25rem;
  background: #fff;
`;

const AboutUsDropDown = () => {
  const navigate = useNavigate();

  return (
    <AboutUsContainer
      variants={containerVariants}
      initial="hidden"
      animate="show"
    >
      <MenuContainer>
        <LabelText
          variants={itemVariants}
          onClick={() => navigate("company/leadership")}
        >
          Leadership
        </LabelText>
        <LabelText
          variants={itemVariants}
          onClick={() => navigate("/company/careers")}
        >
          Careers
        </LabelText>
        <LabelText
          variants={itemVariants}
          onClick={() => navigate("/company/contact-us")}
        >
          Contact Us
        </LabelText>
      </MenuContainer>
    </AboutUsContainer>
  );
};

const isMobileView = window.innerWidth < 800;

const Header = () =>
  isMobileView ? (
    <MobileHeader />
  ) : (
    <MainContainer>
      <HeaderContainer>
        <HeaderWrapper>
          <LogoWrapper to="/">
            <BounceAnimation scale={1.04}>
              <Logo src={EnneaLogo} alt="Logo" />
            </BounceAnimation>
          </LogoWrapper>
          <DropDownMenuContainer>
            <CustomLinkWrapper>
              <BounceAnimation scale={1.02}>
                <CustomLink>
                  <HeaderText>What We Do</HeaderText>
                  <Img src={arrowDown} alt="arrowDown" />
                </CustomLink>
              </BounceAnimation>
              <DropdownContainer
                initial={{ opacity: 0, y: "32px", x: "-18%" }}
                animate={{ opacity: 1, y: 0, x: 0 }}
                transition={{ duration: 1.2, ease: "easeIn" }}
              >
                <ProductDropDown />
              </DropdownContainer>
            </CustomLinkWrapper>
            <CustomLinkWrapper>
              <BounceAnimation scale={1.02}>
                <CustomLink>
                  <HeaderText>Who We Are</HeaderText>
                  <Img src={arrowDown} alt="arrowDown" />
                </CustomLink>
              </BounceAnimation>
              <DropdownContainer
                initial={{ opacity: 0, y: "32px", x: "-18%" }}
                animate={{ opacity: 1, y: 0, x: 0 }}
                transition={{ duration: 1.2, ease: "easeIn" }}
              >
                <AboutUsDropDown />
              </DropdownContainer>
            </CustomLinkWrapper>
          </DropDownMenuContainer>
        </HeaderWrapper>
      </HeaderContainer>
    </MainContainer>
  );

const MenuImg = styled.img`
  width: 2.625rem;
  height: 2.625rem;
  flex-shrink: 0;
`;
const MobileHeaderContainer = styled.div`
  display: flex;
  height: min-content;
  place-content: center;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.5rem;
  `;
const MobileHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e2edf4;
  width: 100%;
`;

const MobileHeader = () => (
  <MobileHeaderContainer>
    <MobileHeaderWrapper>
      <LogoWrapper to="/">
        <BounceAnimation scale={1.04}>
          <Logo src={EnneaLogo} alt="Logo" />
        </BounceAnimation>
      </LogoWrapper>
      <DropDownMenuContainer>
        <MenuImg src={MenuIcon} alt="MenuIcon" />
      </DropDownMenuContainer>
    </MobileHeaderWrapper>
  </MobileHeaderContainer>
);

export default Header;

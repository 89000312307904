import AnimationAdInSight from "common/icons/home_animation_valueconnect.png";
import AnimationValueMedi from "common/icons/home_animation_valuemedi.png";
import AnimationValueSale from "common/icons/home_animation_valuesale.png";
import { motion } from "framer-motion";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import styles from "./Ennea.module.css";

const MainContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.56rem;
  height: 90vh;
  position: relative;
  overflow: hidden;
  @media screen and (max-width: 800px) {
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 2.5rem;
    height: 65vh;
  }
`;

const LeftContainer = styled(motion.div)`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2.5rem;
  padding-left: 9rem;
  @media screen and (max-width: 800px) {
    padding: 0;
  }
`;

const TitleText = styled.div`
  color: #2d2d2d;
  font-family: Outfit;
  font-size: 3.375rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 4.05rem */
  width: 34.1875rem;
  @media screen and (max-width: 800px) {
    font-size: 2.125rem;
    width: 20rem;
  }
`;

const SubTitleText = styled.div`
  color: #2d2d2d;
  font-family: Poppins;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  width: 30rem;
  @media screen and (max-width: 800px) {
    font-size: 1rem;
  }
`;

const EnneaInfo = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <MainContainer>
      <LeftContainer
        initial="hidden"
        animate="visible"
        variants={{
          hidden: { opacity: 0, x: -150 },
          visible: {
            opacity: 1,
            x: 0,
            transition: {
              duration: 0.4,
              ease: "easeInOut"
            }
          }
        }}
      >
        <TitleText>Pioneering Healthcare Sales & Marketing </TitleText>
        <SubTitleText>
          Through supply chain efficiencies and hyper targeted engagement
        </SubTitleText>
      </LeftContainer>
      <div className={styles.animationContainer}>
        <Img
          className={styles.animatedImage}
          src={AnimationAdInSight}
          alt="adInsight"
        />
        <Img
          className={styles.animatedImage}
          onClick={() => navigate("/products/valuemedi")}
          src={AnimationValueMedi}
          alt="valuemedi"
        />
        <Img
          className={styles.animatedImage}
          onClick={() => navigate("/products/valuesale")}
          src={AnimationValueSale}
          alt="valuesale"
        />
      </div>
    </MainContainer>
  );
};

const Img = styled.img`
  cursor: pointer;
`;

export default EnneaInfo;

import Image1 from "common/icons/adInsightImage1.png";
import Image2 from "common/icons/adInsightImage2.png";
import Image3 from "common/icons/adInsightImage3.png";
import Image4 from "common/icons/adInsightImage4.png";
import Image5 from "common/icons/adInsightImage5.png";
import { motion, useAnimation, useMotionValue } from "framer-motion";
import React, { useEffect } from "react";
import styled from "styled-components";

const MainContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  height: 100%;
  padding: 0 1rem;
`;


const OverlayContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(0, 0, 0, 0.2);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  mask-image: linear-gradient(
    to bottom,
    transparent 0%,
    black 10%,
    black 90%,
    transparent 100%
  );
  -webkit-mask-image: linear-gradient(
    to bottom,
    transparent 0%,
    black 10%,
    black 90%,
    transparent 100%
  );

  color: #fff;
  font-family: Outfit;
  font-size: 4rem;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
`;

const VerticalMarquee = () => (
  <MainContainer>
    <Marquee speed={8} direction="up" />
    <Marquee speed={8} direction="down" />
    <Marquee speed={8} direction="up" />
    <OverlayContainer>Coming soon...</OverlayContainer>
  </MainContainer>
);

const MarqueeContainer = styled.div`
  width: 80%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 100%;
`;

const MarqueeContent = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: absolute;
  width: 100%;
`;

const Image = styled.img`
  width: 10rem;
  background: lightgray 50% / contain no-repeat;
  border-radius: 6px;
  margin-bottom: 16px;
`;

const Marquee = ({ speed = 10, direction = "up" }) => {
  const marqueeRef = React.useRef(null);
  const y = useMotionValue(0);
  const controls = useAnimation();

  const directionValue = direction === "up" ? -600 : 600;

  const marqueeVariants = {
    animate: {
      y: [0, directionValue],
      transition: {
        duration: speed,
        repeat: Infinity,
        ease: "linear"
      }
    }
  };

  useEffect(() => {
    controls.start("animate");
  }, [controls]);

  return (
    <MarqueeContainer>
      <MarqueeContent
        ref={marqueeRef}
        style={{ y }}
        animate={controls}
        variants={marqueeVariants}
      >
        <Image src={Image1} alt="img1" />
        <Image src={Image2} alt="img2" />
        <Image src={Image3} alt="img3" />
        <Image src={Image4} alt="img4" />
        <Image src={Image5} alt="img4" />
      </MarqueeContent>
    </MarqueeContainer>
  );
};

export default VerticalMarquee;

import Discoverability from "common/icons/discoverability.svg";
import ExperAdvise from "common/icons/expert_advise.svg";
import Growth from "common/icons/growth.svg";
import MobileMock from "common/icons/mobile_mock_valuemedi.png";
import ValueMediLogo from "common/icons/valuemedi_logo.svg";
import WebMock from "common/icons/web_mock_valuemedi.png";
import Lottie from "lottie-react-web";
import styled from "styled-components";
import ApplicationLinks from "../ApplicationLinks";
import Features from "../Features";
import InfoComponent from "../InfoComponent";
import ClientsAndPartners from "./ClientsAndPartners";

const VMAnimation = require("common/icons/VM_animation.json");

const MainContainer = styled.div`
  width: 100%;
  overflow: hidden;
`;

const Separator = styled.div`
  height: 10.06rem;
`;

export const VMAnimationLottie = (
  <Lottie
    options={{
      animationData: VMAnimation,
      loop: true,
      autoplay: true
    }}
    style={{
      transform: "scale(1.2)",
      width: "100%",
      height: "100%"
    }}
  />
);

const INFO_DATA = {
  logo: ValueMediLogo,
  title: "Transformation with Clarity and Connection",
  description: `Enhancing the supply chain by fostering discoverability and transparency among healthcare distributors, hospitals, and pharmacies.`,
  image: VMAnimationLottie
};

const FEATURES_DATA = {
  title: "Unique Propositions",
  description: "Where Innovation Meets Excellence, Delivering Unmatched Value.",
  features: [
    {
      id: 1,
      title: "Discoverability and Transparency",
      image: Discoverability
    },
    {
      id: 2,
      title: "Fuel for your Business Growth",
      image: Growth
    },
    {
      id: 3,
      title: "Expert Advisory",
      image: ExperAdvise
    }
  ]
};

const APP_LINKS = {
  playStore:
    "https://play.google.com/store/apps/details?id=com.ennea.enneamobile&hl=en_IE",
  appStore: "https://apps.apple.com/in/app/valuemedi/id1549205337",
  mobileImage: MobileMock,
  webImage: WebMock
};

const ValueMedi = () => (
  <MainContainer>
    <InfoComponent data={INFO_DATA} />
    <Features data={FEATURES_DATA} />
    <ClientsAndPartners />
    <Separator />
    <ApplicationLinks links={APP_LINKS} />
  </MainContainer>
);

export default ValueMedi;

import SlideAnimation from "common/components/SlideAnimation";
import ClientImage1 from "common/icons/marquee_a2z.svg";
import ClientImage2 from "common/icons/marquee_aditya.svg";
import PoweredByImage1 from "common/icons/marquee_aws.svg";
import PartnersImage1 from "common/icons/marquee_csq.svg";
import PartnersImage2 from "common/icons/marquee_daxinsoft.svg";
import ClientImage3 from "common/icons/marquee_divya.svg";
import PoweredByImage2 from "common/icons/marquee_docker.svg";
import ClientImage4 from "common/icons/marquee_drugMart.png";
import PartnersImage3 from "common/icons/marquee_easysol.png";
import PoweredByImage3 from "common/icons/marquee_firebase.svg";
import ClientImage5 from "common/icons/marquee_inderbagh.svg";
import PartnersImage5 from "common/icons/marquee_marg.svg";
import PartnersImage4 from "common/icons/marquee_muthoot.svg";
import PoweredByImage4 from "common/icons/marquee_whatsapp.svg";
import Marquee from "react-fast-marquee";
import styled from "styled-components";

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5rem;
  position: relative;
`;
const TextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
`;

const CommonText = styled.div`
  color: #000;
  text-align: center;
  font-family: Outfit;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 60px */
`;

const TitleText = styled(CommonText)`
  width: 426px;
  color: #000;
  text-align: center;
  font-family: Outfit;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 60px */
`;

const MarqueeImage = styled.img`
  margin-right: 3.75rem;
  height: 48px;
`;

const StyledMarquee = styled(Marquee)`
  width: 400px;
`;

const ClientsAndPartnersContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 80px;
`;

const MarqueeContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 120px;
`;

const PoweredByContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 80px;
  width: 80%;
`;

const PoweredByText = styled(CommonText)`
  width: 356px;
`;

const ClientsAndPartners = () => (
  <MainContainer>
    <ClientsAndPartnersContainer>
      <TextContainer>
        <TitleText>
          <SlideAnimation>Our Clients</SlideAnimation>
        </TitleText>
        <TitleText>
          <SlideAnimation>Our Partners</SlideAnimation>
        </TitleText>
      </TextContainer>
      <MarqueeContainer>
        <SlideAnimation
          slideDirection="left"
          animationOptions={{
            hidden: { x: -200 }
          }}
        >
          <StyledMarquee direction="left" style={{ width: "600px" }} gradient>
            <MarqueeImage src={ClientImage1} alt="marquee" />
            <MarqueeImage src={ClientImage2} alt="marquee" />
            <MarqueeImage src={ClientImage3} alt="marquee" />
            <MarqueeImage src={ClientImage4} alt="marquee" />
            <MarqueeImage src={ClientImage5} alt="marquee" />
          </StyledMarquee>
        </SlideAnimation>
        <SlideAnimation
          slideDirection="right"
          animationOptions={{
            hidden: { x: 200 }
          }}
        >
          <StyledMarquee direction="right" style={{ width: "600px" }} gradient>
            <MarqueeImage src={PartnersImage1} alt="marquee" />
            <MarqueeImage src={PartnersImage2} alt="marquee" />
            <MarqueeImage src={PartnersImage3} alt="marquee" />
            <MarqueeImage src={PartnersImage4} alt="marquee" />
            <MarqueeImage src={PartnersImage5} alt="marquee" />
          </StyledMarquee>
        </SlideAnimation>
      </MarqueeContainer>
    </ClientsAndPartnersContainer>
    <PoweredByContainer>
      <PoweredByText>
        <SlideAnimation>Powered by</SlideAnimation>
      </PoweredByText>
      <Marquee width="max-content" autofill>
        <MarqueeImage
          src={PoweredByImage1}
          style={{ marginRight: "10rem" }}
          alt="marquee"
        />
        <MarqueeImage
          src={PoweredByImage2}
          style={{ marginRight: "10rem" }}
          alt="marquee"
        />
        <MarqueeImage
          src={PoweredByImage3}
          style={{ marginRight: "10rem" }}
          alt="marquee"
        />
        <MarqueeImage
          src={PoweredByImage4}
          style={{ marginRight: "10rem" }}
          alt="marquee"
        />
      </Marquee>
    </PoweredByContainer>
    {/* <Ellipse /> */}
  </MainContainer>
);

export default ClientsAndPartners;

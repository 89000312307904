import { motion } from "framer-motion";

const BounceAnimation = ({ children, animationOptions = {}, scale = 1.08 }) => {
  const animationVariants = {
    initial: { scale: 1, y: 0, marginRight: "0" },
    animate: {
      scale,
      transition: { type: "spring", stiffness: 500, damping: 10 }
    },
    ...animationOptions
  };

  return (
    <motion.div initial="initial" whileHover="animate">
      <motion.div variants={animationVariants}>{children}</motion.div>
    </motion.div>
  );
};

export default BounceAnimation;

import { motion, useInView } from "framer-motion";
import { useRef } from "react";

const textVariant = {
  hidden: { opacity: 0 },
  reveal: { opacity: 1 }
};

const TextReveal = ({ text, showOnce = true, animationOptions = {} }) => {
  const characters = Array.from(text);
  const ref = useRef(null);
  const isInView = useInView(ref, { once: showOnce });

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={isInView ? "reveal" : "hidden"}
      transition={{ staggerChildren: 0.02, ...animationOptions }}
    >
      {characters.map((char, index) => (
        <motion.span
          key={`${char}-${index.toString()}`}
          transition={{ duration: 0.5 }}
          variants={textVariant}
        >
          {char}
        </motion.span>
      ))}
    </motion.div>
  );
};

export default TextReveal;

import SlideAnimation from "common/components/SlideAnimation";
import AppStore from "common/icons/app_store_badge.svg";
import PlayStore from "common/icons/google_play_badge.svg";
import { motion, useAnimation, useInView } from "framer-motion";
import { useEffect, useRef } from "react";
import styled from "styled-components";

const OutSideContainer = styled.div`
  display: flex;
  padding: 0rem 12.25rem;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
`;

const MainContainer = styled.div`
  display: flex;
  width: 83.4375rem;
  height: 28.8125rem;
  border-radius: 1.25rem;
  background: #deedff;
  overflow: hidden;
`;

const LeftContainer = styled.div`
  display: flex;
  width: 41.9375rem;
  height: 23.8125rem;
  padding: 3.75rem;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2.5rem;
  flex-shrink: 0;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.25rem;
  align-self: stretch;
`;

const TitleText = styled(motion.div)`
  align-self: stretch;
  color: var(--Neutral---Mine-Shaft, #2d2d2d);
  font-family: Outfit;
  font-size: 3.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 4.0625rem */
  letter-spacing: -0.0625rem;
`;

const SubTitleText = styled(motion.div)`
  color: var(--Neutral---Mine-Shaft, #2d2d2d);
  font-family: Poppins;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.875rem */
`;

const LinksContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
`;

const Img = styled(motion.img)`
  display: flex;
  width: 12.65619rem;
  height: 3.75rem;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const RightContainer = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 1.25rem;
  flex-shrink: 0;
  position: relative;
`;

const MobileMockImage = styled(motion.img)`
  position: absolute;
  width: 16rem;
  z-index: 1;
  left: -120px;
`;

const WebMockImage = styled(motion.img)`
  position: absolute;
  height: 24.54044rem;
  flex-shrink: 0;
`;

const ApplicationLinks = ({
  links: { mobileImage, webImage, playStore, appStore }
}) => {
  const ref = useRef(null);
  const controls = useAnimation();
  const isInView = useInView(ref, { once: true });

  useEffect(() => {
    if (isInView) {
      controls.start("visible");
    }
  }, [isInView, controls]);

  return (
    <OutSideContainer>
      <MainContainer>
        <LeftContainer>
          <TextContainer ref={ref}>
            <TitleText>
              <SlideAnimation slideDirection="bottom">
                Works everywhere. For everyone.
              </SlideAnimation>
            </TitleText>
            <SubTitleText>
              <SlideAnimation slideDirection="bottom">
                Available on iOS, Android and the Web
              </SlideAnimation>
            </SubTitleText>
          </TextContainer>
          <LinksContainer>
            <SlideAnimation>
              <Img
                whileHover={{
                  scale: 1.05,
                  transition: { duration: 0.3, ease: "easeInOut" }
                }}
                whileTap={{ scale: 0.9 }}
                src={PlayStore}
                alt="playStore"
                onClick={() => window.open(playStore, "_blank")}
              />
            </SlideAnimation>
            <SlideAnimation>
              <Img
                whileHover={{
                  scale: 1.05,
                  transition: { duration: 0.3, ease: "easeInOut" }
                }}
                whileTap={{ scale: 0.9 }}
                src={AppStore}
                alt="appStore"
                onClick={() => window.open(appStore, "_blank")}
              />
            </SlideAnimation>
          </LinksContainer>
        </LeftContainer>
        <RightContainer>
          <MobileMockImage
            initial="hidden"
            animate={controls}
            variants={{
              hidden: { opacity: 0.2, y: 400 },
              visible: {
                opacity: 1,
                y: 0,
                transition: {
                  duration: 0.6,
                  ease: "easeIn"
                }
              }
            }}
            src={mobileImage}
            alt="mobileImage"
          />
          <WebMockImage
            initial="hidden"
            animate={controls}
            variants={{
              hidden: { opacity: 0, x: 300 },
              visible: {
                opacity: 1,
                x: 0,
                transition: {
                  duration: 1,
                  ease: "easeIn"
                }
              }
            }}
            src={webImage}
            alt="mobileImage"
          />
        </RightContainer>
      </MainContainer>
    </OutSideContainer>
  );
};

export default ApplicationLinks;

import SlideAnimation from "common/components/SlideAnimation";
import { GradientEllipse } from "common/components/StyledElements";
import PoweredByImage1 from "common/icons/marquee_aws.svg";
import ClientImage1 from "common/icons/marquee_biological.svg";
import PoweredByImage2 from "common/icons/marquee_docker.svg";
import PoweredByImage3 from "common/icons/marquee_firebase.svg";
import ClientImage2 from "common/icons/marquee_saanso.svg";
import PoweredByImage4 from "common/icons/marquee_whatsapp.svg";
import Marquee from "react-fast-marquee";
import styled from "styled-components";

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 7.5rem;
  position: relative;
`;
const MarqueeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3.75rem 0;
  width: 75%;
`;
const TitleText = styled.div`
  flex: 1;
  justify-self: flex-start;
  color: #000;
  text-align: start;
  font-family: Outfit;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
`;
const StyledMarquee = styled(Marquee)`
  flex: 3;
`;
const MarqueeImage = styled.img`
  margin-right: 16rem;
  height: 48px;
`;
const Image = styled.img`
  margin-right: 10rem;
  height: 48px;
`;
const Ellipse = styled(GradientEllipse)`
  position: absolute;
  bottom: -10.25rem;
  left: 0;
`;

const ClientsAndPartners = () => (
  <MainContainer>
    <MarqueeContainer>
      <TitleText>
        <SlideAnimation>Our Clients</SlideAnimation>
      </TitleText>
      <StyledMarquee gradient>
        <MarqueeImage src={ClientImage1} alt="marquee" />
        <MarqueeImage src={ClientImage2} alt="marquee" />
      </StyledMarquee>
    </MarqueeContainer>
    <MarqueeContainer>
      <TitleText>
        <SlideAnimation>Powered by</SlideAnimation>
      </TitleText>
      <StyledMarquee gradient>
        <Image src={PoweredByImage1} alt="marquee" />
        <Image src={PoweredByImage2} alt="marquee" />
        <Image src={PoweredByImage3} alt="marquee" />
        <Image src={PoweredByImage4} alt="marquee" />
      </StyledMarquee>
    </MarqueeContainer>
    <Ellipse />
  </MainContainer>
);

export default ClientsAndPartners;

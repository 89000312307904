import { GradientEllipse } from "common/components/StyledElements";
import TextReveal from "common/components/TextReveal"; // Import the new component
import { motion } from "framer-motion";
import { useEffect } from "react";
import styled from "styled-components";

const RightContainer = styled(motion.div)`
  display: flex;
  flex: 9;
  height: 100%;
  justify-content: flex-start;
  transform: scale(1.1);
  padding-top: 2.875rem;
`;

const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 1.56rem;
  position: relative;
`;

const LeftMainContainer = styled.div`
  display: flex;
  flex: 8;
  justify-content: flex-end;
  align-items: flex-end;
  padding-top: 9.875rem;
  padding-bottom: 9.875rem;
`;
const LeftContainer = styled(motion.div)`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
`;

const LogoImage = styled.img`
  width: 27.25rem;
  height: 5.80656rem;
  flex-shrink: 0;
`;

const TitleText = styled(motion.div)`
  color: #5a5a5a;
  font-family: Outfit;
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 2.6rem */
  width: 42.25rem;
  margin-top: 2.5rem;
  margin-bottom: 1.25rem;
`;

const SubTitleText = styled(motion.div)`
  color: var(--Onboarding-background-grey, #2d2d2d);
  font-family: Poppins;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 2rem */
  width: 39.9375rem;
`;

const Ellipse = styled(GradientEllipse)`
  position: absolute;
  top: 5.75rem;
  left: 2.63rem;
`;

const InfoComponent = ({ data: { logo, title, description, image } }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <MainContainer>
      <LeftMainContainer>
        <LeftContainer
          initial="hidden"
          animate="visible"
          variants={{
            hidden: { opacity: 0, x: -150 },
            visible: {
              opacity: 1,
              x: 0,
              transition: {
                duration: 0.4,
                ease: "easeInOut"
              }
            }
          }}
        >
          <LogoImage src={logo} alt="ValueMedi Logo" />
          <TitleText>
            <TextReveal text={title} />
          </TitleText>
          <SubTitleText>
            <TextReveal
              animationOptions={{ staggerChildren: 0.01 }}
              text={description}
            />
          </SubTitleText>
        </LeftContainer>
      </LeftMainContainer>
      <RightContainer
        initial="hidden"
        animate="visible"
        variants={{
          hidden: { opacity: 0, x: 200 },
          visible: {
            opacity: 1,
            x: 0,
            transition: {
              duration: 0.4,
              ease: "easeInOut"
            }
          }
        }}
        transition={{ delay: 0.7 }}
      >
        {image}
      </RightContainer>
      <Ellipse />
    </MainContainer>
  );
};

export default InfoComponent;

import AppLayout from "common/components/AppLayout";
import ErrorBoundary from "common/components/ErrorBoundary";
import { lazy, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

const Ennea = lazy(() => import("./Ennea"));
const Products = lazy(() => import("./Products"));
const Company = lazy(() => import("./Company"));
const Terms = lazy(() => import("./Terms"));

function App() {
  return (
    <BrowserRouter>
      <ErrorBoundary>
        <Suspense fallback={<div>Loading your content...</div>}>
          <Routes>
            <Route path="/" element={<AppLayout />}>
              <Route index element={<Ennea />} />
              <Route path="products/*" element={<Products />} />
              <Route path="company/*" element={<Company />} />
            </Route>
            <Route path="terms" element={<Terms />} />
            <Route path="privacy" element={<Terms />} />
          </Routes>
        </Suspense>
      </ErrorBoundary>
    </BrowserRouter>
  );
}

export default App;

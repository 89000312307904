import styled from "styled-components";

const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  padding-top: 12rem;

  color: #006eb2;
  text-align: center;
  font-family: Poppins;
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
`;

const Loading = () => <MainContainer>Loading...</MainContainer>;

export default Loading;

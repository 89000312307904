import Automation from "common/icons/automation.svg";
import Behaviour from "common/icons/behaviour.svg";
import MobileMock from "common/icons/mobile_mock_valuesale.png";
import NextGen from "common/icons/next_gen.svg";
import ValueSaleLogo from "common/icons/valuesale_logo.svg";
import WebMock from "common/icons/web_mock_valuesale.png";
import Lottie from "lottie-react-web";
import styled from "styled-components";
import ApplicationLinks from "../ApplicationLinks";
import Features from "../Features";
import InfoComponent from "../InfoComponent";
import ClientsAndPartners from "./ClientsAndPartners";

const VSAnimationJs = require("common/icons/VS_Animation.json");

const MainContainer = styled.div`
  width: 100%;
  overflow: hidden;
`;
const Separator = styled.div`
  height: 10.06rem;
`;

const AnimationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(1.2) translateX(150px);
`;

export const VSAnimation = (
  <Lottie
    options={{
      animationData: VSAnimationJs,
      loop: true,
      autoplay: true
    }}
    style={{
      width: "26.12rem",
      height: "28.875rem"
    }}
  />
);

const INFO_DATA = {
  logo: ValueSaleLogo,
  title: "Unleashing Sales Excellence",
  description: `Maximising sales team productivity by automating routine business processes, delivering unparalleled customer behaviour insights, and offering cutting-edge interactive digital product detailing.`,
  image: <AnimationContainer>{VSAnimation}</AnimationContainer>
};

const FEATURES_DATA = {
  title: "Unique Propositions",
  description: "Where Innovation Meets Excellence, Delivering Unmatched Value.",
  features: [
    {
      id: 1,
      title: "Seamless Automation",
      image: Automation
    },
    {
      id: 2,
      title: "Behavioural Insights",
      image: Behaviour
    },
    {
      id: 3,
      title: "Next-Gen Product Detailing",
      image: NextGen
    }
  ]
};

const APP_LINKS = {
  playStore:
    "https://play.google.com/store/apps/details?id=com.enneasolutions.valuesale&hl=en_IE",
  appStore: "https://apps.apple.com/in/app/value-sale/id1632648006",
  mobileImage: MobileMock,
  webImage: WebMock
};

const ValueSale = () => (
  <MainContainer>
    <InfoComponent data={INFO_DATA} />
    <Features data={FEATURES_DATA} />
    <ClientsAndPartners />
    <Separator />
    <ApplicationLinks links={APP_LINKS} />
  </MainContainer>
);

export default ValueSale;
